import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import HomeLayout from '../components/layouts/HomeLayout'
import Seo from '../components/seo'
import CardList from '../components/common/CardList/CardList'
import Card from '../components/common/Card/Card'
import PagesListBanner from '../components/common/PagesListBanner/PagesListBanner'
import PropTypes from 'prop-types'
import useLink from '../hooks/useLink'

const PageListByTypeTemplate = props => {
  const intl = useIntl()
  const { buildURL } = useLink()
  const location = typeof window !== 'undefined' ? window.location : { pathname: '', search: '' }
  const pages = get(props, 'data.allContentfulPage.edges', []).map(edge => edge.node)

  const searchParams = new URLSearchParams(location.search)

  return (
    <HomeLayout location={location}>
      <Seo title={intl.formatMessage({ id: `pages-list.${props.pageContext.pageType}.title` })} />
      <PagesListBanner
        title={intl.formatMessage({ id: `pages-list.${props.pageContext.pageType}.title` })}
        subtitle={intl.formatMessage({ id: `pages-list.${props.pageContext.pageType}.subtitle` })}
      />
      <div className="container">
        <CardList
          items={pages.map(page => (
            <Card to={buildURL(page)} {...page} key={page.id} />
          ))}
          perPage={12}
          initialPage={searchParams.has('page') ? Number(searchParams.get('page')) : 1}
        />
      </div>
    </HomeLayout>
  )
}

PageListByTypeTemplate.propTypes = {
  pageContext: PropTypes.object,
}

export default PageListByTypeTemplate

export const pageQuery = graphql`
  query PageListByTypeQuery($locale: String!, $pageType: String) {
    allContentfulPage(filter: { node_locale: { eq: $locale }, pageType: { eq: $pageType } }) {
      edges {
        node {
          __typename
          id
          slug
          node_locale
          pageType
          title
          image {
            title
            file {
              url
            }
          }
          updatedAt
        }
      }
    }
  }
`
