import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './pages-list-banner.module.scss'
import classNames from 'classnames'
import { Link, useIntl } from 'gatsby-plugin-intl'

export default function PagesListBanner(props) {
  const intl = useIntl()

  return (
    <div
      className={classNames('with-overlay', styles.banner)}
      style={{
        backgroundImage:
          'url(https://images.unsplash.com/photo-1506514283824-e2a49afa0d06?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1468&q=80)',
      }}
    >
      <div className="overlay" />
      <div className="container content">
        <nav className="mb-4" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{intl.formatMessage({ id: 'article-banner.home' })}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {props.title}
            </li>
          </ol>
        </nav>
        <h1 className={classNames({ 'mb-2': !!props.subtitle })}>{props.title}</h1>
        {props.subtitle && <h5 className="mb-4">{props.subtitle}</h5>}
      </div>
    </div>
  )
}

PagesListBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}
