import * as React from 'react'
import PropTypes from 'prop-types'

import Header from '../common/Header'
import Footer from '../common/Footer/Footer'

const HomeLayout = props => (
  <>
    <Header location={props.location} />
    <main>{props.children}</main>
    <Footer />
  </>
)

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any,
}

export default HomeLayout