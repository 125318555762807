import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Pagination from '../Pagination/Pagination'

export default function CardList(props) {
  const { perPage, initialPage, items } = props
  const totalPages = Math.ceil(items.length / perPage)
  const [currentPage, setCurrentPage] = useState(
    initialPage <= totalPages && initialPage >= 1 ? initialPage - 1 : 0
  )
  const [slice, setSlice] = useState(
    items.slice(currentPage * perPage, (currentPage + 1) * perPage)
  )
  const handleCurrentPageChange = nextPage => {
    if (nextPage === currentPage) return
    setCurrentPage(nextPage)
    const offset = nextPage * perPage
    setSlice(items.slice(offset, offset + perPage))
  }

  return (
    <section className="page-section">
      <div className="row g-3">
        {slice.map((Item, index) => (
          <div className="col-12 col-md-6 col-xl-4" key={index}>
            {Item}
          </div>
        ))}
      </div>
      {items.length > perPage && (
        <Pagination
          perPage={perPage}
          itemsLength={items.length}
          currentPage={currentPage}
          setCurrentPage={handleCurrentPageChange}
        />
      )}
    </section>
  )
}

CardList.propTypes = {
  items: PropTypes.array.isRequired,
  perPage: PropTypes.number,
  initialPage: PropTypes.number,
}

CardList.defaultProps = {
  perPage: 10,
  initialPage: 1,
}
