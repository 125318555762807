import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ChevronLeftIcon from '../icons/chevron-left'
import ChevronDoubleLeftIcon from '../icons/chevron-double-left'
import ChevronRightIcon from '../icons/chevron-right'
import ChevronDoubleRightIcon from '../icons/chevron-double-right'

export default function Pagination(props) {
  const { currentPage, setCurrentPage, itemsLength } = props
  const totalPages = Math.ceil(itemsLength / props.perPage)

  const goToPage = (e, pageNumber) => {
    e.preventDefault()
    setCurrentPage(pageNumber)
    if (typeof window.history.pushState != 'undefined') {
      const state = { url: e.target.href, page: pageNumber }
      window.history.pushState(state, '', state.url)
      setTimeout(() => window.scroll({ top: 0, behavior: 'smooth' }), 100)
    }
  }

  const computePaginationParams = () => {
    const ELLIPSIS_THRESHOLD = 5
    const thresholdHalf = Math.floor(ELLIPSIS_THRESHOLD / 2)
    const r = totalPages - currentPage - 1
    const l = currentPage
    let firstIndex =
      l <= thresholdHalf
        ? 0
        : r <= thresholdHalf
          ? totalPages - ELLIPSIS_THRESHOLD
          : l - thresholdHalf
    let numberOfLinks = Math.min(ELLIPSIS_THRESHOLD, itemsLength)
    let showFirstDots = false
    let showLastDots = false
    if (itemsLength > ELLIPSIS_THRESHOLD) {
      if (firstIndex > 0) {
        showFirstDots = true
      }
      if (firstIndex + ELLIPSIS_THRESHOLD < totalPages) {
        showLastDots = true
      }
    }
    return {
      showLastDots,
      showFirstDots,
      linkItems: Array.from({ length: numberOfLinks }, (_, i) => i + firstIndex),
    }
  }

  const paginationParams = computePaginationParams()

  return (
    <nav aria-label="Page navigation" className="pt-5 d-flex justify-content-center">
      <ul className="pagination">
        {currentPage !== 0 && (
          <>
            <li className="page-item">
              <a
                className="page-link"
                href="?page=1"
                aria-label="Previous"
                onClick={e => goToPage(e, 0)}
              >
                <ChevronDoubleLeftIcon />
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href={`?page=${currentPage}`}
                aria-label="Previous"
                onClick={e => goToPage(e, currentPage - 1)}
              >
                <ChevronLeftIcon />
              </a>
            </li>
          </>
        )}
        {paginationParams.showFirstDots && (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        )}
        {paginationParams.linkItems.map(index => (
          <li className={classNames('page-item', { active: index === currentPage })} key={index}>
            <a className="page-link" href={`?page=${index + 1}`} onClick={e => goToPage(e, index)}>
              {index + 1}
            </a>
          </li>
        ))}
        {paginationParams.showLastDots && (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        )}
        {currentPage !== totalPages - 1 && (
          <>
            <li className="page-item">
              <a
                className="page-link"
                href={`?page=${currentPage + 2}`}
                aria-label="Next"
                onClick={e => goToPage(e, currentPage + 1)}
              >
                <ChevronRightIcon />
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href={`?page=${totalPages}`}
                aria-label="Last Page"
                onClick={e => goToPage(e, totalPages - 1)}
              >
                <ChevronDoubleRightIcon />
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  itemsLength: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}
